.banner-text[data-v-4f17b852]{
  display:flex;
  flex-direction:row;
  flex-wrap:nowrap;
  align-items:center;
  justify-content:center;
  width:100%;
  height:auto;
  min-height:32px;
  background:#ee4981;
  padding:8px 16px;
  border-bottom:none
}
.banner-text[data-v-4f17b852]:hover{
  opacity:1 !important
}
@media(min-width: 640px){
.banner-text[data-v-4f17b852]{
    padding:0
}
}
.banner-text__title[data-v-4f17b852]{
  font-weight:400;
  font-size:17px;
  line-height:23px;
  text-align:center;
  color:#fff;
  margin-bottom:0;
  text-transform:none
}
@media(min-width: 1280px){
.banner-text__title[data-v-4f17b852]{
    font-size:22px;
    line-height:8px
}
}
.banner-text__title--arrow[data-v-4f17b852]{
  display:inline;
  margin-left:8px
}