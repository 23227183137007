.main-brands[data-v-ba1c376a]{
  width:100%;
  margin-bottom:52px;
  order:2
}
.main-brands__title[data-v-ba1c376a]{
  display:flex;
  justify-content:space-between;
  align-items:center;
  margin:40px 14px 28px 21px
}
.main-brands__title-text[data-v-ba1c376a]{
  font-weight:bold;
  font-size:18px;
  line-height:16px;
  color:#373737
}
.main-brands__slider[data-v-ba1c376a]{
  height:58px;
  overflow:hidden;
  margin-bottom:10px
}
.main-brands__slider .main-brands__brand[data-v-ba1c376a]{
  margin-right:20px;
  margin-left:20px
}
.main-brands__brand[data-v-ba1c376a]{
  display:flex;
  flex-direction:column;
  justify-content:center;
  width:auto !important
}
.main-brands__brand-img[data-v-ba1c376a]{
  max-height:100%;
  filter:grayscale(100%)
}
.main-brands__brand-img[data-v-ba1c376a]:hover{
  filter:none
}
.main-brands__link-desktop[data-v-ba1c376a]{
  display:flex;
  flex-direction:row;
  justify-content:center
}
@media(min-width: 1024px){
.main-brands[data-v-ba1c376a]{
    margin-bottom:100px
}
}
@media(max-width: 1280px){
.main-brands__link-desktop[data-v-ba1c376a]{
    display:none
}
.main-brands__link[data-v-ba1c376a]{
    font-size:14px;
    line-height:14px;
    color:#ee4981;
    padding-bottom:5px;
    border-bottom:1px dashed #ee4981;
    text-decoration:none
}
}
@media(min-width: 1280px){
.main-brands[data-v-ba1c376a]{
    margin-bottom:0;
    order:3;
    margin-top:40px
}
.main-brands__title[data-v-ba1c376a]{
    display:none
}
}