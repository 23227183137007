.main-page-wrapper[data-v-1beabc9c]{
  display:flex;
  flex-flow:column
}
.main-page-wrapper__catalog[data-v-1beabc9c]{
  order:1
}
.main-page-wrapper__advantages[data-v-1beabc9c]{
  order:1
}
.main-page-wrapper__brands[data-v-1beabc9c]{
  order:2
}
.main-page-wrapper__blog-news[data-v-1beabc9c]{
  order:3
}
.main-page-wrapper__seo[data-v-1beabc9c]{
  order:3
}
@media(min-width: 1280px){
.main-page-wrapper__brands[data-v-1beabc9c]{
    order:3
}
.main-page-wrapper__blog-news[data-v-1beabc9c]{
    order:2
}
}