.main-slider{
  width:100%;
  margin-top:10px;
  font-size:0
}
.main-slider__placeholder{
  background-color:#f1f1f1;
  border-radius:5px;
  width:100%;
  height:auto
}
.main-slider--loading{
  opacity:0
}
@media(max-width: 1279px){
.main-slider__button--prev,.main-slider__button--next{
    display:none
}
}
.main-slider__hint{
  position:absolute;
  bottom:20px;
  left:30px;
  display:flex;
  align-items:flex-end;
  z-index:3;
  cursor:pointer
}
.main-slider__hint_active{
  border-radius:5px;
  background-color:#fff
}
.main-slider__hint-button{
  position:relative;
  color:#fff;
  font-size:18px;
  text-shadow:0 2px 8px rgba(0,0,0,.8);
  padding:0;
  border-radius:50%;
  margin-left:10px
}
@media(min-width: 1280px){
.main-slider__hint-button{
    font-size:24px
}
}
.main-slider__hint_active .main-slider__hint-button{
  color:#00aafa
}
.main-slider__hint-text{
  padding:10px;
  display:none;
  max-width:215px;
  color:#373737;
  font-size:8px;
  line-height:9px
}
@media(min-width: 1280px){
.main-slider__hint-text{
    max-width:360px;
    font-size:12px;
    line-height:16px
}
}
.main-slider__hint_active .main-slider__hint-text{
  display:block
}
.main-slider__controls{
  display:none
}
.main-slider__img{
  width:100%;
  height:auto;
  border-radius:5px
}
.main-slider__img.swiper-lazy{
  opacity:0
}
.main-slider__img.swiper-lazy-loaded{
  opacity:1
}
.main-slider .preloader{
  position:absolute;
  left:50%;
  top:50%;
  transform:translate(-50%, -50%)
}
.main-slider .swiper-slide{
  border:none;
  min-height:125px;
  width:calc(100% - 46px)
}
.main-slider .swiper-lazy-loading{
  opacity:0
}
@media(min-width: 1280px){
.main-slider__controls{
    display:flex;
    position:absolute;
    display:flex;
    justify-content:space-between;
    width:100%;
    height:100%;
    top:0;
    z-index:1;
    pointer-events:none
}
.main-slider__button{
    cursor:pointer;
    pointer-events:auto
}
.main-slider__button--prev{
    position:absolute;
    top:50%;
    transform:translateY(-50%);
    left:5px;
    z-index:1
}
.main-slider__button--next{
    position:absolute;
    top:50%;
    transform:translateY(-50%);
    right:5px;
    z-index:1
}
.main-slider__new-tooltip{
    position:absolute;
    left:0px;
    bottom:0px
}
.main-slider__img{
    height:470px
}
.main-slider{
    margin-top:0
}
.main-slider .swiper-slide{
    min-height:470px;
    width:100%
}
}