.image-banner{
  display:block;
  width:100%;
  height:auto;
  max-height:310px;
  border-bottom:none
}
.image-banner:hover{
  border:none
}
.image-banner__img{
  width:100%
}