.advantages[data-v-00558727]{
  margin-bottom:40px
}
.advantages__section[data-v-00558727]{
  order:1
}
.advantages__title[data-v-00558727]{
  display:inline-block;
  width:100%;
  font-weight:bold;
  font-size:18px;
  line-height:1.5;
  color:#373737;
  text-decoration:none;
  box-sizing:border-box;
  padding:20px 26px
}
.advantages__wrapper[data-v-00558727]{
  display:flex;
  flex-wrap:wrap;
  overflow:hidden;
  transition:all .3s;
  margin:0 auto;
  width:-webkit-min-content;
  width:min-content
}
.advantages__text[data-v-00558727]{
  text-transform:uppercase;
  color:#373737;
  font-size:18px;
  line-height:22px
}
.advantages__text--small[data-v-00558727]{
  font-size:14px;
  line-height:19px;
  text-transform:unset
}
.advantages__text--small p[data-v-00558727]{
  margin:0
}
.advantages__text-wrapper:hover .advantages__text[data-v-00558727]{
  color:#00aafa
}
.advantages__item[data-v-00558727]{
  display:flex;
  align-items:center;
  width:315px;
  text-decoration:none;
  padding:0 10px 40px
}
.advantages__img[data-v-00558727]{
  margin-right:13px;
  max-width:91px;
  max-height:92px
}
.advantages__btn[data-v-00558727]{
  display:block;
  margin:0 auto;
  position:relative;
  color:#ee4981 !important;
  background:transparent;
  border:transparent !important;
  font-size:14px !important;
  padding:3px;
  outline:none;
  cursor:pointer;
  font-family:inherit
}
.advantages__btn[data-v-00558727]:before{
  content:"";
  display:block;
  position:absolute;
  width:calc(100% - 20px);
  height:0;
  left:0;
  bottom:0
}
.advantages__btn[data-v-00558727]:hover:before{
  opacity:1;
  border-bottom:1px dashed #ee4981
}
@media(min-width: 1024px){
.advantages__wrapper[data-v-00558727]{
    min-width:945px
}
}
@media(min-width: 1280px){
.advantages__title[data-v-00558727]{
    font-size:30px;
    font-weight:normal;
    line-height:30px;
    text-align:center;
    margin-bottom:66px;
    text-transform:uppercase
}
.advantages__item[data-v-00558727]{
    padding:0 25px 45px
}
.advantages__section[data-v-00558727]{
    max-width:1280px;
    padding-top:105px;
    margin:0 auto
}
}
@media(max-width: 1024px){
.advantages__wrapper[data-v-00558727]{
    min-width:610px
}
.advantages__item[data-v-00558727]{
    padding:0 3px 40px;
    width:305px
}
}
@media(max-width: 640px){
.advantages__wrapper[data-v-00558727]{
    min-width:auto
}
.advantages__item[data-v-00558727]{
    padding:0 0 40px
}
}