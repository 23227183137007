[data-v-798a3946] .product-hits__link{
  display:flex;
  flex-direction:column;
  align-items:center;
  width:calc((100% - 66px) / 3);
  color:#373737;
  text-decoration:none;
  font-size:0
}
@media(max-width: 1280px){
[data-v-798a3946] .product-hits__link{
    width:calc((100% - 66px) / 3) !important
}
}
[data-v-798a3946] .product-hits__link .mp-widget{
  margin-bottom:15px
}
[data-v-798a3946] .product-hits__reviews-link:hover{
  color:unset
}
[data-v-798a3946] .product-hits__image{
  width:100%;
  height:auto;
  border-radius:5px;
  opacity:1
}
[data-v-798a3946] .product-hits__title{
  margin:40px 14px 28px 21px;
  display:flex;
  justify-content:space-between;
  align-items:center
}
[data-v-798a3946] .product-hits__title-text{
  font-size:18px;
  line-height:16px;
  font-weight:bold
}
[data-v-798a3946] .product-hits__title-counter{
  display:none
}
[data-v-798a3946] .product-hits__title-link{
  font-size:14px;
  line-height:14px;
  color:#ee4981;
  padding-bottom:5px;
  border-bottom:1px dashed #ee4981;
  text-decoration:none
}
[data-v-798a3946] .product-hits__button{
  display:none
}
[data-v-798a3946] .product-hits__name{
  font-size:12px;
  line-height:16px;
  margin-top:3px;
  margin-bottom:3px;
  color:#373737;
  text-align:center;
  overflow:hidden;
  text-overflow:ellipsis;
  -webkit-line-clamp:2;
  -webkit-box-orient:vertical;
  display:-webkit-box;
  height:32px
}
[data-v-798a3946] .product-hits__price-wrapper{
  display:flex;
  align-items:center;
  justify-content:center
}
[data-v-798a3946] .product-hits__price{
  font-size:18px;
  line-height:25px
}
[data-v-798a3946] .product-hits__price--old{
  font-size:14px;
  line-height:19px;
  position:relative;
  margin-left:6px
}
[data-v-798a3946] .product-hits__price--old::before{
  position:absolute;
  content:"";
  width:100%;
  top:51%;
  border-bottom:1px solid #000
}
[data-v-798a3946] .product-hits__price .price_currency_format{
  display:none
}
[data-v-798a3946] .product-hits__price::after{
  content:"С";
  font-family:"RUBSN";
  margin-left:4px
}
[data-v-798a3946] .product-hits__image-container{
  width:100%;
  display:flex;
  align-items:flex-end;
  justify-content:center
}
[data-v-798a3946] .pointer{
  cursor:pointer
}
[data-v-798a3946] .product-hits__link:hover .product-hits__item p,[data-v-798a3946] .product-hits__link:hover .product-hits__item .product-hits__price-wrapper,[data-v-798a3946] .product-hits__link:hover .product-hits__item .product-hits__name{
  color:#00aafa !important
}
@media(min-width: 1280px){
[data-v-798a3946] .product-hits__title-counter{
    display:inline
}
[data-v-798a3946] .product-hits__title-link{
    display:none
}
[data-v-798a3946] .product-hits__button{
    display:block;
    cursor:pointer
}
[data-v-798a3946] .product-hits__name{
    font-size:14px;
    line-height:20px;
    margin:15px 10px 19px 10px;
    height:40px
}
[data-v-798a3946] .product-hits__title-text{
    font-size:30px;
    line-height:30px;
    font-weight:normal;
    text-transform:uppercase
}
[data-v-798a3946] .product-hits__price-wrapper{
    align-items:baseline
}
[data-v-798a3946] .product-hits__price{
    font-size:28px;
    line-height:24px
}
[data-v-798a3946] .product-hits__price::after{
    margin-left:6px
}
[data-v-798a3946] .product-hits__price--old{
    font-size:18px;
    line-height:24px;
    order:-1;
    margin-left:0;
    margin-right:10px
}
}
@media(max-width: 600px){
[data-v-798a3946] .product-hits__price-wrapper{
    flex-direction:column
}
[data-v-798a3946] .product-hits__price-wrapper .product-hits__price--old{
    margin-left:0
}
}