.main-page-vpt-override{
  margin-top:-20px
}
.page-wrapper__container{
  display:flex;
  flex-direction:column;
  margin-bottom:100px
}
.page-wrapper__container a:hover:not(.acuvue-bonus__link):not(.main-slider__link):not(.tap-bar__nav-link-item):not(.image-banner){
  opacity:.7
}
a:hover{
  color:#00aafa
}
.scroll__container{
  max-width:1440px;
  margin:0 auto
}
.nav-catalog{
  display:none
}
.catalog-menu{
  margin-top:40px
}
.catalog-menu__wrapper{
  display:flex;
  flex-direction:column
}
.catalog-menu__title{
  margin-left:20px;
  margin-bottom:20px;
  margin-top:0;
  font-size:18px;
  line-height:16px;
  color:#373737
}
.catalog-menu__link{
  display:flex;
  justify-content:space-between;
  align-items:center;
  grid-gap:6px;
  gap:6px;
  background-color:#f5f5f5;
  min-height:85px;
  padding-left:24px;
  color:#373737;
  font-size:16px;
  text-transform:uppercase;
  text-decoration:none;
  border-bottom:none;
  margin:0 8px 8px;
  border-radius:5px
}
.catalog-menu__link:visited{
  color:#373737
}
.catalog-menu__link:first-child{
  margin-top:8px
}
.catalog-menu__link-text{
  line-height:1.5;
  text-transform:none
}
.catalog-menu__link-img{
  max-width:125px;
  height:100%;
  object-fit:cover;
  object-position:left
}
.swiper-pagination__bullets{
  display:flex;
  justify-content:center;
  margin-top:13px
}
.swiper-pagination__bullet{
  background-color:#dfe0e4;
  width:12px;
  height:12px;
  display:block;
  border-radius:50%;
  cursor:pointer;
  margin:0 4.5px
}
.swiper-pagination__bullet--active{
  background-color:#00aafa
}
.main-news{
  padding:0 21px;
  margin-bottom:115px
}
.main-news__title{
  width:100%;
  display:flex;
  flex-direction:row;
  justify-content:space-between
}
.main-news__link{
  margin-bottom:40px;
  font-weight:bold;
  font-size:18px;
  line-height:16px;
  color:#373737;
  text-decoration:none;
  text-transform:uppercase
}
.main-news__link_mobile{
  margin-right:15px;
  font-weight:normal;
  font-size:14px;
  line-height:100%;
  letter-spacing:-0.04em;
  color:#ee4981;
  padding-bottom:5px;
  border-bottom:1px dashed #ee4981;
  text-transform:none
}
.main-news__link_news{
  font-style:normal;
  font-weight:400;
  font-size:18px;
  line-height:25px;
  text-transform:none
}
.main-news__list{
  width:100%;
  overflow:hidden;
  height:auto;
  display:grid;
  grid-template-columns:1fr;
  grid-template-rows:1fr 1fr 1fr;
  grid-column-gap:20px;
  column-gap:20px;
  grid-row-gap:24px;
  row-gap:24px
}
.main-news__content{
  display:none
}
.main-news__content:nth-child(-n+3){
  display:block
}
.main-news__date{
  font-style:normal;
  font-weight:normal;
  font-size:13px;
  line-height:16px;
  color:#373737
}
.grecaptcha-badge{
  visibility:hidden
}
.page-footer__scroll_button{
  display:none;
  visibility:hidden
}
@media(min-width: 640px){
.main-news{
    flex:1
}
.main-news__list{
    grid-template-columns:1fr 1fr
}
.main-news__content{
    display:block
}
}
@media(min-width: 1024px){
.main-news{
    margin:0 65px 85px 65px;
    padding:0
}
}
@media(min-width: 1280px){
.scroll__container{
    max-width:1280px;
    margin:0 auto
}
.catalog-menu{
    display:none
}
.nav-catalog{
    display:flex;
    flex-direction:column;
    width:314px;
    min-height:470px;
    flex-shrink:0;
    position:relative
}
.nav-catalog__wrapper{
    display:flex
}
.nav-catalog__item{
    background-color:#f5f5f5;
    margin-right:19px;
    margin-bottom:9px;
    flex:1 1 0;
    padding-left:20px;
    border-radius:5px;
    min-height:60px;
    cursor:pointer
}
.nav-catalog__item:last-child{
    margin-bottom:0
}
.nav-catalog__item:hover{
    background-image:none;
    padding-right:19px;
    border-top-right-radius:0;
    border-bottom-right-radius:0;
    opacity:1
}
.nav-catalog__item:hover{
    margin-right:0
}
.nav-catalog__item:hover:not(.nav-catalog__item--gifts)>.nav-catalog__inner-list-wrapper{
    display:block
}
.nav-catalog__item--no-hover:hover{
    margin-right:19px;
    padding-right:0;
    border-top-right-radius:5px;
    border-bottom-right-radius:5px
}
.nav-catalog__item--lenses .nav-catalog__inner-list{
    display:grid;
    grid-template-columns:150px 218px;
    grid-template-rows:auto;
    grid-template-areas:"frequency popular" "type all-brands"
}
.nav-catalog__item--lenses .nav-catalog__inner-item{
    padding-bottom:4px
}
.nav-catalog__item--lenses .nav-catalog__inner-item:nth-child(1){
    grid-area:frequency
}
.nav-catalog__item--lenses .nav-catalog__inner-item:nth-child(2){
    grid-area:type
}
.nav-catalog__item--lenses .nav-catalog__inner-item:nth-child(3){
    grid-area:popular;
    padding-left:30px;
    border-right:none
}
.nav-catalog__item--lenses .nav-catalog__inner-item:nth-child(4){
    grid-area:all-brands;
    padding-left:30px
}
.nav-catalog__item--lenses .nav-catalog__inner-item:nth-child(4) .nav-catalog__inner-item-see-all{
    font-size:15px;
    line-height:19px;
    color:#00aafa !important;
    border-bottom:1px dotted #00aafa;
    width:-webkit-fit-content;
    width:-moz-fit-content;
    width:fit-content;
    display:block
}
.nav-catalog__item--related .nav-catalog__inner-list{
    display:grid;
    grid-template-columns:150px 218px
}
.nav-catalog__item--related .nav-catalog__inner-item:first-child .nav-catalog__inner-item-title{
    white-space:nowrap
}
.nav-catalog__item--glasses .nav-catalog__inner-list,.nav-catalog__item--frame .nav-catalog__inner-list{
    display:grid;
    grid-template-columns:150px 204px 239px 188px;
    grid-template-rows:auto;
    grid-template-areas:"gender material popular last" "shape lens more last" "shape lens more last";
    padding-top:8px
}
.nav-catalog__item--glasses .nav-catalog__inner-item,.nav-catalog__item--frame .nav-catalog__inner-item{
    padding-bottom:4px
}
.nav-catalog__item--glasses .nav-catalog__inner-item .nav-catalog__inner-item-title,.nav-catalog__item--frame .nav-catalog__inner-item .nav-catalog__inner-item-title{
    margin-bottom:3px !important;
    font-size:15px
}
.nav-catalog__item--glasses .nav-catalog__inner-item .nav-catalog__inner-item-link,.nav-catalog__item--frame .nav-catalog__inner-item .nav-catalog__inner-item-link{
    font-size:15px;
    line-height:18.6px
}
.nav-catalog__item--glasses .nav-catalog__inner-item .nav-catalog__inner-item-see-all,.nav-catalog__item--frame .nav-catalog__inner-item .nav-catalog__inner-item-see-all{
    font-size:15px;
    line-height:19px;
    color:#00aafa !important;
    border-bottom:1px dotted #00aafa;
    width:-webkit-fit-content;
    width:-moz-fit-content;
    width:fit-content;
    display:none
}
.nav-catalog__item--glasses .nav-catalog__inner-item:nth-child(1),.nav-catalog__item--frame .nav-catalog__inner-item:nth-child(1){
    grid-area:gender
}
.nav-catalog__item--glasses .nav-catalog__inner-item:nth-child(2),.nav-catalog__item--frame .nav-catalog__inner-item:nth-child(2){
    grid-area:shape
}
.nav-catalog__item--glasses .nav-catalog__inner-item:nth-child(3),.nav-catalog__item--frame .nav-catalog__inner-item:nth-child(3){
    grid-area:material;
    padding-left:30px
}
.nav-catalog__item--glasses .nav-catalog__inner-item:nth-child(4),.nav-catalog__item--frame .nav-catalog__inner-item:nth-child(4){
    grid-area:lens;
    padding-left:30px
}
.nav-catalog__item--glasses .nav-catalog__inner-item:nth-child(5),.nav-catalog__item--frame .nav-catalog__inner-item:nth-child(5){
    grid-area:popular;
    padding-left:30px
}
.nav-catalog__item--glasses .nav-catalog__inner-item:nth-child(6),.nav-catalog__item--frame .nav-catalog__inner-item:nth-child(6){
    grid-area:more;
    padding-left:30px
}
.nav-catalog__item--glasses .nav-catalog__inner-item:nth-child(7),.nav-catalog__item--frame .nav-catalog__inner-item:nth-child(7){
    grid-area:last;
    padding-top:33px
}
.nav-catalog__item--glasses .nav-catalog__inner-item:nth-child(7) .nav-catalog__inner-item-see-all,.nav-catalog__item--frame .nav-catalog__inner-item:nth-child(7) .nav-catalog__inner-item-see-all{
    display:block
}
.nav-catalog__item--glasses .nav-catalog__inner-item:nth-child(n+8),.nav-catalog__item--frame .nav-catalog__inner-item:nth-child(n+8){
    display:none
}
.nav-catalog__item--related .nav-catalog__inner-item-links{
    margin-top:3px
}
.nav-catalog__item-title{
    display:flex;
    justify-content:space-between;
    align-items:center;
    height:100%;
    grid-gap:6px;
    gap:6px;
    margin:0;
    font-size:16px;
    color:#373737;
    text-decoration:none
}
.nav-catalog__item-title-text{
    flex:1;
    word-break:break-word
}
.nav-catalog__item-title-block{
    max-width:130px;
    height:100%;
    overflow:hidden;
    display:flex;
    align-items:center;
    justify-content:center
}
.nav-catalog__item-title-img{
    height:100%;
    transform:translateX(8%);
    max-width:none
}
.nav-catalog__item-title:hover{
    color:#373737;
    text-decoration:none
}
.nav-catalog__inner-list-block{
    display:flex;
    flex-direction:row;
    flex-wrap:nowrap;
    justify-content:space-between
}
.nav-catalog__inner-banner{
    width:100%;
    max-width:470px;
    height:auto;
    max-height:325px;
    padding-top:16px;
    box-sizing:content-box
}
.nav-catalog__inner-list-wrapper{
    display:none;
    position:absolute;
    box-sizing:border-box;
    left:100%;
    top:0;
    z-index:10;
    background-color:#f5f5f5;
    width:929px;
    height:470px;
    padding:20px 45px;
    overflow:auto
}
.nav-catalog__inner-main-link{
    position:relative;
    text-decoration:none;
    display:inline-block;
    color:#00aafa;
    background-color:#fff;
    text-align:center;
    padding:0 33px 0 20px;
    line-height:35px;
    margin-bottom:40px;
    border-radius:2px
}
.nav-catalog__inner-main-link::after{
    content:"";
    position:absolute;
    width:0;
    height:0;
    border-top:5px solid transparent;
    border-bottom:5px solid transparent;
    border-left:5px solid #49a6db;
    top:50%;
    right:15px;
    transform:translateY(-50%)
}
.nav-catalog__inner-list{
    display:flex;
    padding-top:8px
}
.nav-catalog__inner-item{
    padding-right:30px;
    border-right:1px solid #dfe0e4
}
.nav-catalog__inner-item:last-child{
    padding-right:0;
    padding-left:30px;
    border-right:none
}
.nav-catalog__inner-item:first-child{
    padding-left:0
}
.nav-catalog__inner-item-see-all{
    display:none
}
.nav-catalog__inner-item-links{
    display:flex;
    flex-direction:column
}
.nav-catalog__inner-item-title{
    margin-top:0;
    margin-bottom:3px !important;
    font-size:15px;
    line-height:19px;
    color:#00aafa !important;
    min-height:19px
}
.nav-catalog__inner-item-link{
    text-decoration:none;
    font-size:15px;
    line-height:18.6px;
    color:#373737
}
.swiper-pagination__bullets{
    display:none
}
.nav-catalog__wrapper,.product-hits{
    box-sizing:border-box;
    max-width:1243px;
    margin:0 auto;
    width:100%
}
.main-news{
    display:flex;
    flex-direction:column;
    margin:0;
    padding:0
}
.main-news__content{
    padding-bottom:12px;
    border-bottom:1px solid #f3f3f3
}
.main-news__link_mobile{
    display:none
}
.main-news__list{
    grid-row-gap:12px;
    row-gap:12px
}
}