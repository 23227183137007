.seo-article__more__button{
  cursor:pointer
}
.seo-section{
  width:auto;
  padding:0 12px 0 21px;
  order:4
}
.seo-section p{
  font-size:14px;
  text-transform:none;
  line-height:unset !important;
  color:#373737 !important;
  margin-bottom:unset !important
}
.seo-section .seo-article{
  display:inline-block;
  font-weight:normal;
  color:#373737;
  font-size:14px;
  text-transform:none
}
.seo-section .seo-article__title h1{
  font-weight:bold;
  font-size:18px;
  line-height:25px;
  margin:0 0 20px;
  padding-left:unset !important;
  color:unset !important;
  padding-top:unset !important
}
.seo-section .seo-article__content{
  height:auto
}
.seo-section .seo-article__content__text{
  height:214px;
  width:100%;
  margin:0 0 15px;
  line-height:19px;
  overflow:hidden
}
.seo-section .seo-article__content__text-columns{
  column-width:365px;
  column-count:3;
  grid-column-gap:30px;
  column-gap:30px
}
.seo-section .seo-article__content__text p:first-child{
  margin-top:0
}
.seo-section .seo-article__content__text p:last-child{
  margin-bottom:0
}
.seo-section .seo-article__content__text a{
  font-size:14px;
  color:#00aafa !important;
  text-decoration:none !important
}
.seo-section .seo-article__content__text a:visited{
  color:#80aee4
}
.seo-section .seo-article__content__text a:hover{
  color:#00aafa;
  opacity:1
}
.seo-section .seo-article__content.all .seo-article__content__text{
  height:auto
}
.seo-section .seo-article__more{
  text-align:left
}
.seo-section .seo-article__more__button{
  display:inline-block;
  position:relative;
  letter-spacing:-0.04em;
  line-height:1;
  color:#ee4981;
  padding-bottom:5px;
  text-decoration:none;
  border-bottom:1px dashed transparent
}
.seo-section .seo-article__more__button:hover{
  border-bottom:1px dashed #ee4981;
  opacity:1
}
.seo-section .seo-article__more__button:after{
  content:"";
  position:absolute;
  top:6px;
  right:-18px;
  width:0;
  height:0;
  border:5px solid transparent;
  border-top:6px solid #ee4981
}
.seo-section .seo-article .seo-article__content.all+.seo-article__more .seo-article__more__button:after{
  top:0px;
  border:5px solid transparent;
  border-bottom:6px solid #ee4981
}
@media(min-width: 1024px){
.seo-section{
    padding:0 65px 0 65px
}
}
@media(min-width: 1280px){
.seo-section{
    box-sizing:border-box;
    padding:0 80px 0 100px;
    max-width:1440px;
    margin:0 auto;
    width:100%
}
.seo-section p{
    font-size:12px
}
.seo-section .seo-article__title h1{
    font-weight:normal;
    font-size:30px;
    line-height:30px;
    text-transform:uppercase;
    text-align:center;
    margin-bottom:50px
}
.seo-section .seo-article__content__text{
    height:192px;
    margin:0 0 45px;
    font-size:12px;
    line-height:24px;
    text-align:justify
}
.seo-section .seo-article__content__text a{
    font-size:12px
}
.seo-section .seo-article__more{
    display:flex;
    justify-content:center;
    align-items:center
}
}