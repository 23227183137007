.blog{
  margin-bottom:50px
}
.blog .blog__link{
  text-decoration:none
}
.blog .blog__title:hover{
  color:#00aafa
}
.blog .blog--text{
  display:inline-block;
  color:#fff;
  text-decoration:none;
  text-align:center;
  width:100%;
  margin:0;
  box-sizing:border-box;
  text-transform:uppercase;
  line-height:unset
}
.blog .blog__last-override_title{
  margin-bottom:unset;
  color:#fff
}
.blog .blog__last-override_subtitle{
  margin-bottom:unset;
  color:#fff
}
.blog .blog__last-override_read-articles{
  color:#00aafa;
  margin-bottom:unset;
  line-height:unset
}
.blog .blog__image-fallback{
  background-color:#d5dae0;
  max-height:352px;
  height:100%
}
.blog-news{
  width:auto;
  display:flex;
  flex-direction:column;
  order:3
}
.blog .blog__title{
  font-weight:bold;
  font-size:18px;
  line-height:16px;
  color:#373737;
  text-align:left;
  padding:0 20px 20px
}
.blog .blog__pagination{
  display:flex;
  flex-direction:row;
  justify-content:center
}
.blog .blog__pagination .swiper-pagination-bullet{
  width:12px;
  height:12px;
  background:#00aafa;
  margin:20px 5px 0
}
.blog .blog__item{
  position:relative;
  background-color:#00aafa;
  margin:0;
  width:calc((100% - 66px) / 3);
  height:354px;
  border-radius:5px;
  font-size:0;
  overflow:hidden
}
.blog .blog__item .blog__item-title{
  font-weight:bold;
  font-size:18px;
  line-height:25px;
  text-transform:uppercase;
  padding:33px 25px 60px
}
.blog .blog__item .blog__item-title--last{
  padding-bottom:50px
}
.blog .blog__item .blog__item-text-label{
  font-size:14px;
  line-height:16px;
  padding:0 15px 10px
}
.blog .blog__item .blog__item-subtitle{
  font-size:14px;
  line-height:19px;
  padding:0 15px 15px;
  text-transform:none
}
.blog .blog__item .blog__item-link{
  box-sizing:border-box;
  border-radius:5px;
  background:#fff;
  color:#00aafa;
  padding:22px 0;
  font-weight:600;
  font-size:14px;
  line-height:16px
}
.blog .blog__item--last{
  padding:20px 23px;
  box-sizing:border-box;
  text-decoration:none
}
.blog .blog__image-wrapper{
  position:absolute;
  top:0;
  left:0;
  height:100%;
  width:100%;
  z-index:1
}
.blog .blog__image-wrapper img{
  padding:0;
  max-width:100%;
  height:100%;
  object-fit:cover
}
.blog .blog__meta{
  display:none
}
.blog .blog__content{
  position:relative;
  z-index:2
}
.blog .blog__content p{
  color:#fff
}
@media(min-width: 1280px){
.blog{
    width:590px;
    margin-right:58px;
    margin-bottom:0
}
.blog .blog__title{
    display:flex;
    font-weight:700;
    font-size:18px;
    line-height:16px;
    color:#373737;
    text-align:left;
    margin-bottom:40px;
    padding-bottom:0
}
.blog .blog__item{
    display:none;
    position:relative;
    width:590px;
    min-height:275px;
    padding:30px 50px 23px;
    margin:0;
    box-sizing:border-box;
    overflow:hidden
}
.blog .blog__item:first-child{
    display:block
}
.blog .blog__item .blog__item-title{
    font-weight:600;
    font-size:30px;
    line-height:33px;
    margin-bottom:5px
}
.blog .blog__item .blog__item-label{
    margin-bottom:15px;
    font-size:10px;
    line-height:11px
}
.blog .blog__item .blog__item-subtitle{
    font-size:14px;
    line-height:24px;
    text-transform:none;
    margin-bottom:25px;
    padding:0 65px
}
.blog .blog__image-wrapper{
    position:absolute;
    top:0;
    left:0;
    height:100%;
    width:100%;
    z-index:1
}
.blog .blog__image-wrapper img{
    padding:0;
    width:100%;
    height:100%;
    object-fit:cover
}
.blog .blog__content{
    position:relative;
    z-index:2
}
.blog .blog__content p{
    color:#fff
}
.blog .blog__meta{
    display:flex;
    justify-content:center;
    align-items:center;
    position:relative;
    z-index:3
}
.blog .blog__meta .blog__meta-item{
    width:auto;
    font-weight:bold;
    font-size:11px;
    line-height:15px;
    margin:0 10px;
    color:#fff
}
.blog .blog__icon{
    background:url(/var/resources/static/images/blog-count1bf9.png) center/contain no-repeat;
    width:16px;
    height:10px;
    display:inline-block;
    margin-right:7px;
    color:#fff
}
.blog .blog__link:active{
    text-decoration:none
}
.blog .blog__pagination{
    display:none
}
.blog-news{
    box-sizing:border-box;
    max-width:1280px;
    margin:0 auto 130px auto;
    flex-direction:row;
    flex-wrap:nowrap;
    justify-content:space-between;
    order:2
}
}
@media(min-width: 640px)and (max-width: 1024px){
.blog .blog__item{
    width:calc((100% - 56px) / 2);
    transform:translateX(20px)
}
}
@media(max-width: 640px){
.blog .blog__item{
    width:calc(100% - 46px)
}
}