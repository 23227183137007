.tooltip[data-v-677a588e]{
  position:absolute;
  left:50%;
  top:calc(100% + 5px);
  transform:translateX(-50%);
  display:none;
  z-index:1
}
.tooltip__wrapper[data-v-677a588e]{
  position:relative;
  cursor:pointer
}
.tooltip__wrapper:hover .tooltip[data-v-677a588e]{
  display:block
}
@media(max-width: 1280px){
.tooltip__wrapper:hover .tooltip--mobile-disable[data-v-677a588e]{
    display:none
}
}
.tooltip__wrapper--inline[data-v-677a588e]{
  display:inline
}
.tooltip__text[data-v-677a588e]{
  padding:10px;
  background-color:#fff;
  border:1px solid #e2e2e2;
  border-radius:2px;
  font-weight:300;
  font-size:11px;
  line-height:20px;
  color:#373737;
  white-space:nowrap
}